
// .home-container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 20px;
//   background-image: linear-gradient(to top, #101869, #211a6c, #2e1d6f, #392072, #432375, #40226e, #3e2167, #3b2060, #2d1a4e, #21153c, #160d2a, #0b001a);
//   height: 100vh;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     height: auto; // Allows the container to grow as needed
//     padding: 20px; // Adjust padding as necessary
//     overflow-x: hidden; // Prevents horizontal scrolling
//   }

//   .left-section {
//     flex: 1;
//     display: flex;
//     flex-direction: column;
//     align-items: start;
//     text-align: start;
//     margin-left: 40px;

//     @media (max-width: 768px) {
//       margin-top: 5rem; // Adjust height to fit content
//       margin-left: 0; // Reset margin
//       padding-right: 20px; // Add padding to prevent content touching screen edges
//       align-items: center; // Center align items for better mobile view
//       text-align: center; // Center align text for a better mobile view
//     }

//     h1 {
//       font-size: 3em;
//       margin-bottom: 10px;
//       color: white;
//       max-width: 600px;
//       line-height: 1.2;
//       @media (max-width: 768px) {
//         font-size: 2.5em; // Adjust font size for smaller screens
//       }
//     }

//     p {
//       font-size: 1.2em;
//       color: white;
//       max-width: 600px;
//       margin-bottom: 20px;
//       @media (max-width: 768px) {
//         font-size: 1em; // Adjust font size for smaller screens
//       }
//     }

//     .buttons {
//       display: flex;
//       justify-content: flex-start; // Aligns children to the start (left)
//       align-items: flex-start;
//       gap: 10px;

//       @media (max-width: 768px) {
//         flex-direction: column; // Stack buttons vertically on mobile
//         align-items: center; // Center buttons on mobile
//       }

//       .btn {
//         &.get-started, &.sign-in {
//           border: none;
//           border-radius: 20px;
//           color: #FFFFFF;
//           font-weight: bold;
//           padding: 12px 30px;
//           margin: 10px;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           gap: 10px;
//           transition: all 0.3s ease;

//           &:hover {
//             transform: scale(1.05);
//           }
//         }

//         &.get-started {
//           background-image: linear-gradient(to right top, #3848f1, #6237d2, #7327b4, #781997, #770c7d);

//           &:hover {
//             background-image: linear-gradient(to right, #ffffff, #edd3f3);
//             color: #9b23b9;
//           }
//         }

//         &.sign-in {
//           background-color: transparent;
//           text-decoration: underline;

//           &:hover {
//             color: #050065;
//           }
//         }
//       }
//     }
//   }

//   .right-section {
//     flex: 1;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     @media (max-width: 768px) {
//       height: auto; // Adjust height to fit content
//       padding: 20px; // Add padding to prevent content touching screen edges
//     }

//     img {
//       width: 30%;
//       height: auto;

//       @media (max-width: 768px) {
//         width: 60%; // Adjust image size for smaller screens
//       }
//     }
//   }
// }

/* Other styles for cards-container, pricing-container, etc. */


.home-container {
  display: flex;
  justify-content: center; // This centers the child horizontally
  align-items: center; // This centers the child vertically
  padding: 20px;
  background-image: linear-gradient(168deg, black 0%, #67347F 100%);
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto; // Allows the container to grow as needed
    padding: 20px; // Adjust padding as necessary
    overflow-x: hidden; // Prevents horizontal scrolling
  }
}

.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; // Center align items for better visual balance
  text-align: center; // Center text alignment
  margin-left: 0; // Remove left margin to use full container width

  @media (max-width: 768px) {
    margin-top: 5rem; // Adjust height to fit content
    padding-right: 20px; // Add padding to prevent content touching screen edges
  }

  .revolutionizing {
    font-size: 4em;
    font-family: 'Orbitron', sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
    color: white;
    word-wrap: break-word;
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.8); /* Increased glow effect */

    @media (max-width: 768px) {
      font-size: 2.5em; // Adjust font size for smaller screens
    }
  }

  .future-communication{
    font-size: 3em;
    font-family: 'Orbitron', sans-serif;
    word-wrap: break-word;

    margin-bottom: 10px;
    color: white;
    @media (max-width: 768px) {
      font-size: 2.5em; // Adjust font size for smaller screens
    }
  }

  p {
    font-size: 1.2em;
    font-family: 'Roboto', sans-serif;

        word-wrap: break-word;
    color: white;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 1em; // Adjust font size for smaller screens
    }
  }

  .buttons {
    display: flex;
    justify-content: center; /* Center align buttons for a balanced look */
    align-items: center;
    gap: 10px;
  
    @media (max-width: 768px) {
      flex-direction: column; /* Stack buttons vertically on mobile */
    }
  
    .btn {
      border: none;
      border-radius: 20px;
      color: #FFFFFF;
      font-weight: bold;
      padding: 12px 30px;
      margin: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      transition: all 0.3s ease;
  
      &.get-started {
        width: 174px; /* Define the width */
        height: 60px; /* Define the height */
        padding-left: 20px; /* Padding left */
        padding-Right: 20px; /* Padding right */
        padding-Top: 6px; /* Padding top */
        padding-Bottom: 6px; /* Padding bottom */
        border: 1px solid white; /* White solid border */
        justify-Content: 'flex-start'; /* Align items to the start */
        background: transparent; /* Make background transparent */
  
        &:hover {
          background-image: linear-gradient(to right, #ffffff, #edd3f3);
          color: #9b23b9;
        }
      }
  
      &.sign-in {
        background-color: transparent;
        text-decoration: underline;
  
        &:hover {
          color: #050065;
        }
      }
    }
  }
  
}



.features-section {
  
  margin-top: 40px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  text-align: center; /* Center-align text */

  .section-small-title {
    font-size: 1.5em;
    background-image: linear-gradient(to left, #AB00FC 0%, #67347F 50%, #2B1B35 100%);
    font-family: 'Orbitron', sans-serif;
    font-weight: 700;
    background-clip: text;
    color: transparent;
    display: inline-block;
    margin-bottom: 20px; 

    @media (max-width: 768px) {
      font-size: 1.2em;
    }
  }
  
  .section-large-title {
    font-family: 'Orbitron', sans-serif;
    font-weight: 700;
    color:#2B1B35;
    font-size: 2.5em;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
    
      font-size: 2em;
    }
  }

  .section-description {
    font-size: 1em;
    color: #2B1B35;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    opacity: 0.6;
    @media (max-width: 768px) {
      font-size: 0.9em;
    }
  }

  .features-row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
    flex-wrap: wrap;

    @media (max-width: 1024px) {
      justify-content: space-around;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}





.new-section {
  text-align: left;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-aligns the flex children */

 

  .colored-title {
    font-size: 1.5em;
    background-image: linear-gradient(to left, #AB00FC 0%, #67347F 50%, #2B1B35 100%);
    font-family: 'Orbitron', sans-serif;
    font-weight: 700;
    background-clip: text;
    color: transparent; /* Make the text color transparent to show the gradient */
    display: inline-block; /* Or inline, depending on layout requirements */
    margin-top: 40px;
  }

  .section-large-title,
  .inline-large-title {
    font-family: 'Orbitron', sans-serif;
    font-weight: 700;
    color:#2B1B35;
    font-size: 2.5em;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .section-description {
    font-size: 1em;
    color: #2B1B35;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    opacity: 0.6;
    text-align: center;
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      font-size: 0.9em;
    }
  }

  .cards-row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;  // Added a gap between cards

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .card {
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 269px;
    height: 105px;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid #2B1B35;
    overflow: visible; // Allow the icon to show outside the top
  }

  .card-icon {
    width: 48px;
    height: 45px;
    position: absolute;
    top: -22.5px; // Position the icon so half of it is outside
    left: 10px;  // Adjust based on design needs
  }

  .card-content {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    word-wrap: break-word;
    text-align: center;
    color: #2B1B35;
  }

  .card-content strong {
    font-weight: 700;
  }
}



.remarkable-section {
  height: auto; // Change from fixed height to auto for flexibility
  padding-top: 74px;
  padding-bottom: 74px;
  background: linear-gradient(168deg, black 0%, #67347F 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.remarkable-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.remarkable-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.remarkable-title {
  font-size: 3em;
  font-family: 'Orbitron', sans-serif;
  font-weight: 700;
  color: white;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.8); // Glow effect
  word-wrap: break-word;
  text-align: center; // Center text for consistency

  @media (max-width: 768px) {
    font-size: 2.5em; // Smaller font size for better fit
    margin-bottom: 10px; // Ensure spacing is consistent
  }
}

.remarkable-description {
  opacity: 0.6;
  align-self: stretch;
  text-align: center;
  color: white;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  strong {
    font-weight: 700;
    font-size: 0.9em;
  }

  @media (max-width: 768px) {
    padding: 0 20px; // Add padding for better text wrapping
  }
}

.remarkable-get-started-btn {
  margin-top: 2rem;
  height: 60px;
  padding: 6px 20px; // Consolidated padding
  border-radius: 50px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  background: none;
  cursor: pointer;
  
  &:hover {
    background-image: linear-gradient(to right, #ffffff, #edd3f3);
    color: #9b23b9;
  }

  @media (max-width: 768px) {
    margin-top: 1rem; // Reduced margin on smaller screens
    font-size: 20px; // Smaller font size for the button
  }
}





.two-column-section {

  display: flex;
  background-image: linear-gradient(to right bottom, #3848f1, #353ac3, #2f2d98, #26216e, #1c1648, #1c1440, #1b1338, #191130, #241742, #311d54, #402267, #502779);
  padding: 40px;

  .big-title-column {

    flex-basis: 20%;
    display: flex;
    align-items: start;
    justify-content: start;

    h1 {
      font-size: 2em; // Adjust as needed
      color: white; // Adjust text color as needed
    }
  }

  .paragraph-column {
    flex-basis: 80%;
    display: flex;
    flex-direction: column; // This ensures vertical stacking of child elements
    align-items: flex-start; // Align content to the start (top)
    justify-content: center; // Align content to the start (top)
    text-align: left; // Align text to the left

    p {
      color: white; // Adjust text color as needed
      font-size: 1em; // Adjust as needed
      padding: 0 20px;
    }
  }
}

.faq-button {
  background-image: linear-gradient(to right top, #3848f1, #6237d2, #7327b4, #781997, #770c7d);

  border: none;
  border-radius: 20px;
  color: #ffffff;
  font-weight: bold;
  padding: 12px 30px;
  margin: 10px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    box-shadow: 0 0 5px #000000;
  }
}



.process-section {
  background: linear-gradient(128deg, #DBCDE4 0%, white 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 100%;
}

.process-content {
  width: 90%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.process-title {
  font-size: 2em;
  background-image: linear-gradient(to left, #AB00FC 0%, #67347F 50%, #2B1B35 100%);
  font-family: 'Orbitron', sans-serif;
  font-weight: 700;
  background-clip: text;
  color: transparent;
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
}

.process-description {
  opacity: 0.6;
  color: black;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  text-align: center;
  padding: 0 5%;
}

.process-diagram {
  width: 100%; // Ensures the diagram container fits the content width
  max-width: 1091px; // Limits the maximum width
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  position: relative; // Needed for absolute positioning of child elements

  img {
    width: 100%; // Makes the image responsive
    max-width: 550px; // Limits the maximum width of the image
    height: auto; // Maintains the aspect ratio
    @media (max-width: 768px) {
      max-width: 300px; // Smaller max width for smaller devices
    }
  }
}

.process-footer {
  width: 90%;
  max-width: 1091px;
  text-align: center;
  color: black;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  word-wrap: break-word;
}



.faq-section {
  background-image: linear-gradient(to top, #f9eefc, #f9f3fe, #faf7ff, #fcfbff, #ffffff);  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;

  .section-small-title {
    font-size: 1.5em;
    background-image: linear-gradient(to left, #AB00FC 0%, #67347F 50%, #2B1B35 100%);
    font-family: 'Orbitron', sans-serif;
    font-weight: 700;
    background-clip: text;
    color: transparent;
    display: inline-block;
    margin-bottom: 20px; 

    @media (max-width: 768px) {
      font-size: 1.2em;
    }
  }
  
  .section-large-title {
    font-family: 'Orbitron', sans-serif;
    font-weight: 700;
    color:#2B1B35;
    font-size: 2.5em;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
    
      font-size: 2em;
    }
  }

  .section-description {
    margin-top: 50px;
    font-size: 1em;
    margin: 0.5rem;

  }

  .features-row {
    width: 70%; // Set the width to 70%
    margin: auto; // Center the container
    display: flex;
    flex-direction: column;
    align-items: center;
  }

 
}