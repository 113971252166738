.footer {
  background-image: linear-gradient(149deg, black 0%, #67347F 100%);    padding: 100px;
    text-align: center;
  
    .footer-row {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
  
      .footer-column {
        flex: 1;
        margin: 0 10px;
  
        img {
          max-width: 30%;
          height: auto;
        }
  
        .footer-title {
          color: rgb(201, 201, 201);
          margin-bottom: 30px;
          font-weight: 100;
        }
  
        a {
          display: block;
          color: white;
          font-weight: bold;
          text-decoration: none;
          margin-bottom: 5px;
  
          &:hover {
            color: purple;
          }
          
        }
      } &:last-child { // Targeting the second footer-row
        a {
          color: white;
          margin: 0 10px; 
          font-size: 0.9em; 
        }
      }


    }
  
    @media (max-width: 768px) {
      .footer-row {
        flex-direction: column;
      }
    }
  }
  