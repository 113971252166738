.faq-container {
    width: 100%;
    margin: auto;
    padding: 20px;
  }
  
  .faq-item {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px; // Space between items
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        box-shadow: 0 0 5px #000000;
      }
    
    .faq-question {
        color: black;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .question-icon, .dropdown-icon {
        // Style the icons as needed
      }
    }
  
    .faq-answer {
      border-top: 1px solid #ddd;
      padding-top: 10px;
      margin-top: 10px;
    }
  
    &.open {
      background-color: black;
      color: white;
  
      .faq-question {
        color: white;
      }
  
      .faq-answer {
        color: white;
        background-color: black;
      }
    }
  }
  