.card {
  background-color: rgba(91, 255, 190, 0.23);
  width: 300px;
  height: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 30px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out; // Smooth transition for transform property

 &:hover {
    transform: scale(1.05); // Slightly scale up the card on hover
  }

  .card-image-container {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center; // Centers the image horizontally

    .card-image {
      height: 100%;
      width: auto;
    }
  }

  .card-content {
    padding: 15px;
    text-align: center; // Centers the text

    h3 {
      margin-top: 0;
    }
  }
}
