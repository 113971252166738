.demo-container {
  background-image: linear-gradient(to top, #f9eefc, #f9f3fe, #faf7ff, #fcfbff, #ffffff);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
  min-height: 100vh; 

  .demo-title {
    font-size: 1.5em;
    background-image: linear-gradient(to left, #9900c1, #8916b4, #7b1fa7, #6d239a, #60268c, #6b2589, #762486, #7f2383, #a21b85, #c30c83, #e3007c, #ff0071);
    background-clip: text;
    color: transparent;
    display: inline-block;
    margin-top: 80px; // Margin top for title
  }

  .demo-headline {
    font-size: 1.5rem; // Increased font size for the headline
    margin: 20px 0; // Added margin for spacing between the headline and other elements
  }

  .phone-container {
    margin-top: 20px; // Margin to separate phone container from the headline
   
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .demo-container {
    justify-content: center; // Center content vertically on smaller screens
    padding: 20px; // Add padding for smaller screens
  }

  .demo-title, .demo-headline {
    font-size: 1em; // Adjust font size for smaller screens
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; // High z-index to ensure it's on top of other elements
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 600px; 
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
}

.modal-button {
  padding: 10px 20px;
  background-image: linear-gradient(to right top, #3848f1, #6237d2, #7327b4, #781997, #770c7d);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
}
