.customization-container {
    padding: 20px;
    overflow-x: hidden; // Prevent horizontal scrolling
  
    max-width: 100%;
    height: 100vh; // Set the height to be 100% of the viewport height
  
    background-image: linear-gradient(to right top, #9acff4, #bad6f8, #d9e4fc, #e6e9ff, #ffffff);
  
    .customization-page {
      .customization-title {
        text-align: center;
      }
  
      .customization-subtitle {
        text-align: center;
        font-size: 1rem;
      }
  
      .columns-container {
        display: flex;
        gap: 20px;
        flex-wrap: wrap; 
  
        .column {
          flex: 1;
          padding: 20px;
          margin-top: 10px;
          background-color: white; // Set background color to white
          border-radius: 12px; // Increase border-radius for rounded edges
          @media (max-width: 768px) {
            flex-basis: 100%; // Full width on smaller screens
            max-width: 100%; // Ensure it doesn't overflow
          }
          .option,
          .sub-option,
          .sub-sub-option,
          .input-container,
          .message-type-selector {
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            cursor: pointer;
            border: 1px solid #ccc;
            padding: 10px;
            border-radius: 4px;
  
            &:hover {
              background-color: #f0f0f0;
            }
  
            &.selected {
              background-color: #7e0eab;
              color: white;
            }
  
            input[type="text"],
            input[type="file"],
            select {
              flex-grow: 1;
              margin: 0 10px;
              padding: 8px 12px;
              border: 1px solid #ccc;
              border-radius: 4px;
              font-size: 1rem;
            }
  
            &:focus {
              outline: none;
              border-color: #7e0eab;
            }
  
            label {
              margin-left: 10px;
  
            }
          }
  
          .button-functionality {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            color: red;
  
            select {
              margin-right: 10px;
            }
          }
  
          .input-fields {
            h6 {
              margin-bottom: 10px;
            }
  
            .input-field {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
  
              input[type="text"] {
                flex-grow: 1;
                margin: 0 10px;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 4px;
              }
  
              label {
                display: flex;
                align-items: center;
  
                input[type="checkbox"] {
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
  
      .submit-button-container {
        text-align: center;
        margin-top: 20px;
  
        .btn.get-started {
          background-image: linear-gradient(to right, #7e0eab, #e30ba6);
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
          margin-right: 20PX;
  
          &:hover {
            background-image: linear-gradient(to right, #bd4ada, #3743c7);
            transform: scale(1.05);
          }
        }
  
        .btn.test-chatbot{
          background-image: linear-gradient(to right, #7e0eab, #e30ba6);
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
  
          &:hover {
            background-image: linear-gradient(to right, #bd4ada, #3743c7);
            transform: scale(1.05);
          }
        }
  
  
      }
    }
  
    .cards-row {
      display: flex;
      gap: 20px; // Space between two columns
  
      .column {
        flex: 1; // Both columns take equal space
  
        .card {
          background-color: white;
          padding: 15px;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          margin-bottom: 10px; // Space between cards in the first column
          &:last-child {
            margin-bottom: 0; // Remove margin for the last card
          }
          label {
            display: block;
            margin-bottom: 10px;
          }
  
          input[type="checkbox"] {
            margin-right: 5px;
          }
  
          select, input[type="file"] {
            width: 100%;
            padding: 8px 12px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 1rem;
  
            &:focus {
              outline: none;
              border-color: #7e0eab;
            }
  
          }
          &.full-height {
            flex-grow: 2; // Makes the card in the second column grow taller
          }
        }
      }
    }
  
  
  }
  
  .input-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    .add-option-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #7e0eab;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 8px;
      margin-right: 10px;
      cursor: pointer;
      font-size: 1rem;
      height: 40px; // Adjust as needed
      width: 40px; // Adjust as needed
  
      &:hover {
        background-color: #9b4ed9;
      }
    }
  
    .new-option-input {
      flex-grow: 1;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px 12px;
      font-size: 1rem;
  
      &:focus {
        outline: none;
        border-color: #7e0eab;
      }
    }
  }
  
  .custom-input[type="text"] {
    width: 200px; /* Width for text inputs */
  }
  
  .custom-input[type="select"] {
    width: 50px; /* Width for dropdown selectors */
  }
  
  .custom-input {
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 1rem;
    height: 50px;
  
    &:focus {
      outline: none;
      border-color: #9b4ed9;
    }
  }
  
  .input-group {
    position: relative;
    margin-bottom: 10px;
  }
  
  .input-label {
    position: absolute;
    top: -10px;
    left: 15px;
    background-color: white;
    padding: 0 5px;
    color: gray;
    font-size: smaller;
    pointer-events: none;
  }
  
  .button-functionality {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  
    select.custom-input, input.custom-input {
      flex-grow: 1;
      margin: 0;
    }
  }
  
  /* Adjust this rule to specifically target your dropdown selectors */
  select.custom-input {
    width: 60px;
  }
  
  
  
  
  // here is the new column  
  
  .new-columns-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  
    .first-column, .second-column {
      width: 50%;
      display: flex;
      flex-grow: 1; // This makes both columns grow equally
  
      flex-direction: column;
      gap: 10px;
  
      .page-id-card, .rules-card, .business-type-container, .file-upload-container {
        background-color: white; // Match existing card color
        padding: 10px;
        border-radius: 12px; // Match existing border radius
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Match existing shadow
        display: flex;
        flex-direction: column;
        align-items: flex-start; // Align contents to the start
  
        h3 {
          margin-bottom: 10px; // Space below the heading
        }
  
        input, select {
          width: 75%; // Adjusted width
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 5px;
          font-size: 1rem;
          margin-bottom: 10px; // Space below input/select
        }
      }
  
      .rules-card {
        flex-grow: 1; // Take up the remaining space in the first column
        .rule {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 5px;
  
          p {
            margin-right: 10px; // Space between text and switch
          }
  
          .switch {
            position: relative;
            display: inline-block;
            width: 30px; // Smaller width
            height: 17px; // Smaller height
  
            input {
              opacity: 0;
              width: 0;
              height: 0;
            }
  
            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              transition: .4s;
              border-radius: 17px;
  
              &:before {
                position: absolute;
                content: "";
                height: 13px;
                width: 13px;
                left: 2px;
                bottom: 2px;
                background-color: white;
                transition: .4s;
                border-radius: 50%;
              }
            }
  
            input:checked + .slider {
              background-color: #7e0eab; // Custom color for checked state
            }
  
            input:checked + .slider:before {
              transform: translateX(13px); // Slide the knob
            }
          }
        }
      }
  
      .business-type-container {
        height: 25%; // Set to 25% of the height
  
        select {
          width: 100%; // Full width of the container
        }
      }
  
      .file-upload-container {
        flex-grow: 3; // Takes the remaining 75%
        align-items: center;
  
        .file-drop-area {
          border: 2px dashed #ccc;
          border-radius: 10px;
          width: 80%; // Adjusted width
          height: 70%; // Adjusted height
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          margin-top: 20px;
  
          .file-drop-icon {
            font-size: 2rem;
            margin-bottom: 10px;
          }
  
          p {
            text-align: center;
          }
        }
      }
    }
  }
  
  
  .chatbot-container {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 100vh; /* Take up full viewport height */
    position: fixed; /* Overlay on top of other content */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    z-index: 10; /* Ensure it's above other content */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: adds a semi-transparent overlay for focus */
  }
  
  .close-chatbot {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: none;
    color: #fff; /* Adjust color as needed */
    font-size: 24px; /* Adjust size as needed */
    cursor: pointer;
  }
  
  
  
  