.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #0b001a00;
  transition: box-shadow 0.3s ease-in-out;

  &.scrolled {
    background-color: #0b001abc;
  }

  .navbar-logo img {
    width: 50px;
    height: auto;
  }

  .navbar-links {
    display: flex;
    align-items: center;


    a {
      margin-right: 4rem;

      text-decoration: none;
      color: white;

      &:hover {
        text-decoration: underline;
        text-decoration-color: #7e0eab;
      }
    }

    @media (max-width: 768px) {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #fff;
      flex-direction: column;
      padding: 10px 0;
      text-align: center;

      &.open {
        display: flex;
      }

      a {
        color: #333;
        padding: 10px;
        display: block;
        text-decoration: none;

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }

  .navbar-signin {
    a {
      text-decoration: none;
      color: #333;
      margin-right: 30px;

      &.signin-button {
        background-image: linear-gradient(to right top, #3848f1, #6237d2, #7327b4, #781997, #770c7d);
        color: white;
        border: none !important;
        padding: 10px 30px;
        border-radius: 25px;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background-image: linear-gradient(to right, #ffffff, #edd3f3);
          color: #9b23b9;
          transform: scale(1.1);
        }
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .burger-menu {
    display: none;

    @media (max-width: 768px) {
      display: block;
      margin-right: 50px;
      cursor: pointer;

      svg {
        color: white;
      }
    }
  }
}
