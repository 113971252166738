.checkout-container {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 100%; 
  
    .stripe-pay-btn {
        margin-top: 20px;
      background-image: linear-gradient(to right, #7e0eab, #e30ba6);
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
  
      &:hover {
        background-image: linear-gradient(to right, #bd4ada, #3743c7);
        transform: scale(1.05);
      }
  
      &:disabled {
        background: grey;
        cursor: not-allowed;
      }
    }
  }
  