.clients-container {
  background-color: white;
  text-align: center;
  padding: 60px 20px; /* Reduced side padding for smaller screens */

  .clients-heading {
    color: black;
    margin-bottom: 30px;
  }

  .logos-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr); /* 8 logos per row for larger screens */
    gap: 20px; /* Space between logos */
    justify-content: center; /* Center the grid items horizontally */

    @media (max-width: 1024px) {
      grid-template-columns: repeat(8, 1fr); /* 8 logos per row for medium screens */
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(6, 1fr); /* 6 logos per row for small screens */
    }

    @media (max-width: 480px) {
      grid-template-columns: repeat(4, 1fr); /* 4 logos per row for very small screens */
    }

    .logo {
      max-width: 50px;
      height: auto;
      filter: grayscale(100%); /* Black and white filter */
      margin: 0 auto; /* Center each logo in its grid cell */
    }
  }
}
