.aboutUs {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;

    h5 {
        // Add styles for h5 header here
    }

    .content, .secondView {
        min-height: 100vh; // Making each section take up one full page view
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .leftSection, .rightSection {
        flex: 1;
        min-width: 300px;
        max-width: 50%;
    }

    .leftSection {
        // Add styles for the left section here
        h2 {
            // Add title styling here
        }
        p {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    .rightSection img {
        margin-left: 50px;
        max-width: 80%;
        height: auto;
    }

    .secondView {
        p {
            color: #333; // Styling for second view paragraph
            font-size: 18px;
            line-height: 1.6;
            margin: 20px;
            text-align: justify;
        }
    }

    .btn {
        color: #333; // Matching paragraph styling
        font-size: 18px;
        line-height: 1.6;
        text-align: center;
        border: none;
        border-radius: 20px;
        padding: 12px 30px;
        margin-right: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        transition: all 0.3s ease;

        &.get-started {
            background-color: rgb(91, 0, 133);
            color: white;

            &:hover {
                background-image: linear-gradient(to right, #ffffff, #edd3f3);
                color: #9b23b9;
                border-color: #9b23b9;
                transform: scale(1.05);
            }
        }

        &.main {
            background-color: rgb(0, 156, 246);
            color: white;

            &:hover {
                background-image: linear-gradient(to right, #ffffff, #edd3f3);
                color: #9b23b9;
                border-color: #9b23b9;
                transform: scale(1.05);
            }
        }
    }

    // Responsive adjustments for smaller devices
    @media (max-width: 768px) {
        .content, .secondView {
            margin-top: 50px;
            flex-direction: column;
            text-align: center;
        }

        .leftSection, .rightSection {
            max-width: 100%;
        }

        .rightSection img {
            margin-left: 0;
        }

        .btn {
            margin-top: 20px; // Additional margin for buttons onsmall devices
        }
        }
        }
