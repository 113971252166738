.feature-card {
  width: 270px;
  height: 207px;
  padding-top: 32px; // Adjust padding to position the content lower
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  background: rgba(114, 55, 149, 0.25);
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  overflow: visible; // Allow elements to show outside the box
  transition: background-color 0.3s ease, box-shadow 0.3s ease; // Smooth transition for background and shadow
  margin: 1rem;

  &:hover {
    background: rgba(70, 31, 92, 0.8); // Darker background on hover
    box-shadow: 0 0 15px rgba(114, 55, 149, 0.75); // Glow effect using the original color
  }

  .icon-section {
    width: 64px;
    height: 64px;
    background: #723795;
    box-shadow: 5px 10px 24px -4px rgba(114, 55, 149, 0.67);
    border-radius: 32px;
    position: absolute;
    top: -32px; // Half outside the top of the card
    left: 24px; // Aligned with the left padding of the card
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 64px; // Reduced width to make the icon smaller
      height: 64px; // Reduced height to keep the icon proportionally smaller
    }
  }

  .content-section {
    align-self: stretch;
    color: black;
    font-size: 22px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    word-wrap: break-word;
    margin-top: 36px; // Give space to avoid overlapping the icon

    p {
      color: rgba(0, 0, 0, 0.55);
      font-size: 16px;
      font-weight: 500;
      margin-top: 10px;
    }
  }
}
