.iphone-14 {
  width: 300px;
  height: 600px;
  background-color: #000;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.iphone-14-screen {
  flex-grow: 1;
  background-color: #fff;
  overflow: hidden;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
}

.chat-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #d4d4d4;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.message {
  margin: 15px;
  padding: 10px;
  border-radius: 20px;
  max-width: 70%;
  word-wrap: break-word;
}

.message.bot {
  background-color: #e0e0e0;
  align-self: flex-start;
  margin-top: 30px;
}

.message.user {
  background-color: #dd00ff;
  align-self: flex-end;
  color: white;
}

.quick-replies {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.quick-reply {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 5px 10px;
  margin: 2px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
}

.chat-input-area {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f8f8f8;

  input {
    flex-grow: 1;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 10px;
    margin-right: 10px;
  }

  button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 15px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
}

/* Styles for button messages */
.button-message {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 20px;
  margin: 10px 0;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%; /* Make button width to 100% of the container */
    margin-top: 10px;
    padding: 8px 35px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    text-align: center; /* Center button text */

    &:hover {
      background-color: #0056b3;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}

.template-message {
  background-color: #f0f0f0;
  padding: 0;
  border-radius: 20px;
  margin: 10px 0;
  align-self: center; // Adjust this to center the entire block if necessary
  display: flex;
  flex-direction: column;
  align-items: center; // This ensures everything aligns to the center horizontally
  min-height: 300px; // Adjusted to fit content of two buttons comfortably

  .template-image {
    margin-bottom: 15px; // Margin for spacing below the image
    width: 100%;
    height: 120px;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .template-title {
    font-weight: bold;
    margin: 10px 20px;
  }

  .template-description {
    font-size: 0.9em;
    margin-bottom: 15px;
    width: 160px; 
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
text-align: center;
  }

  .template-content {
    width: 100%; // Ensures the container takes full width
    display: flex;
    flex-direction: column;
    align-items: center; // Centers the buttons and other content vertically
  }

  button {
    width: 70%;
    padding: 8px 15px;
    margin-bottom: 10px; // Margin between buttons
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;

    &:hover {
      background-color: #0056b3;
    }
  }
}

/* Carousel Container adjusted for better visibility */
.carousel-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto; // Enables horizontal scrolling
  align-items: flex-start; // Aligns items to the top of the container
  width: 100%; // Full width of the parent container
  min-height: 300px; // Increased minimum height
  padding: 15px 0; // Adjusted padding for better spacing
  white-space: nowrap;
  box-sizing: border-box;
}

/* Individual Template Messages within the Carousel */
.message.template-message {
  flex: 0 0 auto;
  width: 300px; // Width of each carousel item
  margin-right: 20px; // Right margin for spacing between items
  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-child {
    margin-right: 0; // No right margin for the last item
  }
}

/* Responsive adjustments for smaller devices */
@media (max-width: 768px) {
  .carousel-container {
    min-height: 250px; // Smaller height for mobile devices
  }

  .carousel-container .message.template-message {
    width: 250px; // Smaller width to better fit mobile screens
  }
}
