.payment-page {
    padding: 20px;
  
    .plan-selection {
        text-align: center;

        h2 {
            margin-bottom: 20px;
        }

        .plans {
            display: flex;
            justify-content: center;
            gap: 20px; // Spacing between cards

            // Media query for screens smaller than 768px
            @media (max-width: 768px) {
                flex-direction: column; // Stack plans vertically on smaller screens
                align-items: center; // Center the plans horizontally
            }
        }
    }

    .payment-method-selection {
        margin-top: 20px;
        text-align: center;

        h2 {
            margin-bottom: 15px;
        }

        .payment-options {
            display: flex;
            justify-content: center;
            gap: 20px;

            .payment-option {
                width: 250px;
                height: 150px;
                background-color: #e9e9e9f9;
                color: rgb(57, 57, 57);
                border: 1px solid #aaa2a2f9;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                padding: 10px;
                border-radius: 10px;
                cursor: pointer;
                transition: transform 0.3s ease-in-out, border-color 0.3s ease-in-out;

                &:hover {
                    transform: scale(1.05);
                }

                &.selected {
                    border: 2px solid blue;
                }

                img {
                    max-width: 20%;
                    max-height: auto;
                }

                svg {
                    font-size: 3rem;
                }
            }
        }
    }

    .promo-code-container {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;

        .promo-code-input {
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #ccc;
        }

        .apply-btn {
            background-image: linear-gradient(to right, #7e0eab, #e30ba6);
            color: white;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            text-align: center;

            &:hover {
                background-image: linear-gradient(to right, #bd4ada, #3743c7);
                transform: scale(1.05);
            }
        }
    }
}
