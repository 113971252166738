.privacyPolicy {
    
    .title {
        text-align: center;
        margin-top: 20px;
        // Additional styling for the title
    }

    .content {
        display: flex;
        justify-content: center; // Center horizontally
        align-items: center; // Center vertically
        flex-direction: column; // Stack elements vertically
        text-align: center; 

        margin: 50px 100px;
        .leftColumn, .rightColumn {
            padding: 20px;
        }

      
        .rightColumn {
            width: 100%; // Adjust width as needed
            padding: 20px;
        }
    }

    .section-small-title {
        
        font-size: 1.5em;
        background-image: linear-gradient(to left, #9900c1, #8916b4, #7b1fa7, #6d239a, #60268c, #6b2589, #762486, #7f2383, #a21b85, #c30c83, #e3007c, #ff0071);
        background-clip: text;
        -webkit-background-clip: text; // For cross-browser compatibility
        color: transparent;
        display: inline-block;
        margin-bottom: 20px; // Spacing after title
        margin-top: 40px;
    }

    // Add responsive design if needed
    @media (max-width: 768px) {
        .content {
            flex-direction: column;

            .leftColumn, .rightColumn {
                width: 100%;
            }
        }
    }
}
