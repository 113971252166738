.pricing-plan {
    background-color: #e9e9e9f9;
    color: rgb(57, 57, 57);
    width: 300px; 
    height: 250px; 
    border-radius: 10px;  
    border: 1px solid #aaa2a2f9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
    overflow: hidden;
    transition: transform 0.3s ease-in-out; // Smooth transition for transform property

    &:hover {
      transform: scale(1.01); // Slightly scale up the card on hover
    }
    
    &.selected {
      background-color: #e9e9e9f9; 
      color: rgb(57, 57, 57);
      border: 2px solid blue;



    }
  
    .plan-details {
      padding: 20px;
      text-align: center;
  
      .price {
        font-size: 2em;
        margin-bottom: 10px;
      }
  
      .plan-name {
        font-size: 1.5em;
        margin-bottom: 15px;
      }
  
      .features {
        list-style: none;
        padding: 0;
  
        li {
          margin-bottom: 5px;
        }
      }
  
      .get-started {
        background-color: #7e0eab; 
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #9b23b9;
        }
      }
    }
  }
  